import styled from 'styled-components'

import { breakpoints } from 'src/utils/styles/global-styles'

export const Header = styled.p`
  font-family: 'FilsonPro';
  font-size: 40px;
  color: black;
  margin: 0px;
  line-height: 1.2;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 36px;
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 24px;
    line-height: 1.2;
  }
`
